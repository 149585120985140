// components/CrearHorario.js
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DirectorService from '../../services/directorService';

const bloquesHorarios = [
    '08:30 - 10:00',
    '10:30 - 12:00',
    '12:05 - 13:00',
    '13:05 - 14:00',
    '14:01 - 15:15',
    '15:16 - 16:30'
];

function CrearHorario() {
    const [cursos, setCursos] = useState([]);
    const [cursoId, setCursoId] = useState('');
    const [cursoInfo, setCursoInfo] = useState(null);
    const [asignaturas, setAsignaturas] = useState([]);
    const [horarios, setHorarios] = useState(bloquesHorarios.map(bloque => ({ bloque, asignaturas: [] })));

    useEffect(() => {
        const fetchCursos = async () => {
            try {
                const response = await DirectorService.obtenerCursos();
                const cursosData = response.cursos; // Ajustar para acceder a la propiedad 'cursos'
                console.log('Cursos:', cursosData);
                setCursos(Array.isArray(cursosData) ? cursosData : []);
            } catch (error) {
                console.error('Error al obtener cursos:', error);
            }
        };

        fetchCursos();
    }, []);

    useEffect(() => {
        if (cursoId) {
            const fetchAsignaturas = async () => {
                try {
                    const response = await DirectorService.obtenerAsignaturas();
                    const asignaturasData = response.asignaturas;
                    const cursoAsignaturas = Array.isArray(asignaturasData) ? asignaturasData : [];
                    setAsignaturas(cursoAsignaturas);

                    const cursoData = cursos.find(curso => curso.id === parseInt(cursoId));
                    setCursoInfo(cursoData);
                } catch (error) {
                    console.error('Error al obtener asignaturas:', error);
                }
            };

            fetchAsignaturas();
        }
    }, [cursoId, cursos]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const sourceIndex = parseInt(result.source.droppableId);
        const destinationIndex = parseInt(result.destination.droppableId);

        const newHorarios = Array.from(horarios);
        const [movedItem] = sourceIndex === -1 
            ? asignaturas.splice(result.source.index, 1) 
            : newHorarios[sourceIndex].asignaturas.splice(result.source.index, 1);
        
        if (destinationIndex === -1) {
            asignaturas.splice(result.destination.index, 0, movedItem);
        } else {
            newHorarios[destinationIndex].asignaturas.splice(result.destination.index, 0, movedItem);
        }

        setHorarios(newHorarios);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = horarios.flatMap(horario => 
                horario.asignaturas.map(asignatura => ({
                    curso_id: cursoId,
                    asignatura_id: asignatura.id,
                    dia_semana: horario.bloque.split(' - ')[0],
                    hora_inicio: horario.bloque.split(' - ')[0],
                    hora_fin: horario.bloque.split(' - ')[1]
                }))
            );
            await DirectorService.crearHorario(data);
            alert('Horario creado correctamente');
        } catch (error) {
            console.error('Error al crear el horario:', error);
            alert('Error al crear el horario');
        }
    };

    return (
        <div>
            <h3>Crear Horario</h3>
            <form onSubmit={handleSubmit}>
                <select value={cursoId} onChange={(e) => setCursoId(e.target.value)} required>
                    <option value="">Seleccionar Curso</option>
                    {cursos.map(curso => (
                        <option key={curso.id} value={curso.id}>
                            {curso.nombre} - {curso.grado}
                        </option>
                    ))}
                </select>
                {cursoInfo && (
                    <div>
                        <h4>{cursoInfo.nombre} - {cursoInfo.grado}</h4>
                    </div>
                )}
                <button type="submit">Guardar Horario</button>
            </form>
            <div style={{ display: 'flex' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <div style={{ flex: 1 }}>
                        <h3>Calendario</h3>
                        {horarios.map((horario, index) => (
                            <Droppable key={horario.bloque} droppableId={index.toString()}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} style={{ margin: '10px 0', padding: '10px', border: '1px solid black' }}>
                                        <h4>{horario.bloque}</h4>
                                        <div style={{ minHeight: '50px' }}>
                                            {horario.asignaturas.map((asignatura, index) => (
                                                <Draggable key={asignatura.id} draggableId={asignatura.id.toString()} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{ padding: '5px', margin: '5px 0', border: '1px solid blue', ...provided.draggableProps.style }}
                                                        >
                                                            {asignatura.nombre}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </div>
                    <div style={{ flex: 1 }}>
                        <h3>Asignaturas</h3>
                        <Droppable droppableId="-1">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {asignaturas.map((asignatura, index) => (
                                        <Draggable key={asignatura.id} draggableId={asignatura.id.toString()} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{ padding: '5px', margin: '5px 0', border: '1px solid green', ...provided.draggableProps.style }}
                                                >
                                                    {asignatura.nombre}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>
            </div>
        </div>
    );
}

export default CrearHorario;
