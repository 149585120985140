import React, { useState, useEffect } from 'react';
import directorService from '../../services/directorService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './directorCss/verAsignaturas.css'; // Importar el archivo CSS para estilos

function VerAsignaturas() {
  const [asignaturas, setAsignaturas] = useState([]);
  const [editingAsignatura, setEditingAsignatura] = useState(null);
  const [formData, setFormData] = useState({
    nombre: ''
  });

  // Obtener las asignaturas
  useEffect(() => {
    const fetchAsignaturas = async () => {
      try {
        const asignaturasData = await directorService.obtenerAsignaturas();
        setAsignaturas(asignaturasData.asignaturas);
      } catch (error) {
        console.error("Error al obtener las asignaturas:", error);
        toast.error('Error al obtener las asignaturas. Inténtalo nuevamente.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    fetchAsignaturas();
  }, []);

  const handleDelete = async (id) => {
    try {
      await directorService.eliminarAsignatura(id);
      setAsignaturas(asignaturas.filter((asignatura) => asignatura.id !== id));
      toast.success('Asignatura eliminada con éxito.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error al eliminar la asignatura:", error);
      toast.error('Error al eliminar la asignatura. Inténtalo nuevamente.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleEdit = (asignatura) => {
    setEditingAsignatura(asignatura);
    setFormData({
      nombre: asignatura.nombre
    });
  };

  const handleCancel = () => {
    setEditingAsignatura(null);
    setFormData({
      nombre: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSave = async () => {
    try {
      await directorService.modificarAsignatura(editingAsignatura.id, formData);
      setAsignaturas(asignaturas.map((asignatura) => asignatura.id === editingAsignatura.id ? { ...asignatura, ...formData } : asignatura));
      setEditingAsignatura(null);
      setFormData({
        nombre: ''
      });
      toast.success('Asignatura modificada con éxito.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error al modificar la asignatura:", error);
      toast.error('Error al modificar la asignatura. Inténtalo nuevamente.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="Asignaturas-container">
      <h2>Asignaturas</h2>
      <table className="styled-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {asignaturas.map((asignatura) => (
            <tr key={asignatura.id}>
              <td>{asignatura.id}</td>
              <td>{asignatura.nombre}</td>
              <td>
                <button className="edit-button" onClick={() => handleEdit(asignatura)}>Modificar</button>
                <button className="delete-button" onClick={() => handleDelete(asignatura.id)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingAsignatura && (
        <div className="edit-form">
          <h3>Modificar Asignatura</h3>
          <label>
            Nombre:
            <input
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
            />
          </label>
          <div className="edit-form-buttons">
            <button className="save-button" onClick={handleSave}>Aceptar</button>
            <button className="cancel-button" onClick={handleCancel}>Cancelar</button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default VerAsignaturas;
