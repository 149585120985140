// src/components/profesor/VerResultados.js
import React from 'react';

const VerResultados = ({ idCursoAsignatura }) => {
    return (
        <div>
            <h2>Resultados del Curso {idCursoAsignatura}</h2>
            {/* Aquí puedes agregar la lógica para mostrar los resultados */}
        </div>
    );
};

export default VerResultados;
