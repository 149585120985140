import React, { useEffect, useState } from "react";
import EstudianteService from "../../services/estudianteService"; 
import "./estudianteCss/misNotas.css";

const MisNotas = () => {
    const [notasPorAsignatura, setNotasPorAsignatura] = useState({});

    useEffect(() => {
        const fetchNotas = async () => {
            try {
                const calificaciones = await EstudianteService.obtenerCalificaciones();

                console.log("Calificaciones obtenidas:", calificaciones);

                // Agrupar notas por asignatura
                const agrupadas = calificaciones.reduce((acumulador, nota) => {
                    const { asignatura, nota_numero, nota: valorNota } = nota;

                    if (!acumulador[asignatura]) {
                        acumulador[asignatura] = {
                            nombre: asignatura,
                            notas: Array(8).fill(null), // Espacios para 8 notas
                        };
                    }

                    // Colocar la nota en la posición correspondiente
                    acumulador[asignatura].notas[nota_numero - 1] = valorNota;

                    return acumulador;
                }, {});

                // Calcular promedio para cada asignatura
                Object.keys(agrupadas).forEach((asignatura) => {
                    const totalNotas = agrupadas[asignatura].notas.filter((n) => n !== null);
                    agrupadas[asignatura].promedio =
                        totalNotas.length > 0
                            ? totalNotas.reduce((suma, n) => suma + n, 0) / totalNotas.length
                            : 0;
                });

                console.log("Datos agrupados:", agrupadas);
                setNotasPorAsignatura(agrupadas);
            } catch (error) {
                console.error("Error al obtener las notas:", error);
            }
        };

        fetchNotas();
    }, []);

    if (Object.keys(notasPorAsignatura).length === 0) {
        return <div>Cargando...</div>;
    }

    return (
        <div className="notas-container">
            <h2>Mis Notas</h2>
            <table className="tabla-notas">
                <thead>
                    <tr>
                        <th>Asignatura</th>
                        <th>N1</th>
                        <th>N2</th>
                        <th>N3</th>
                        <th>N4</th>
                        <th>N5</th>
                        <th>N6</th>
                        <th>N7</th>
                        <th>N8</th>
                        <th>Promedio</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(notasPorAsignatura).map(([asignatura, datos]) => (
                        <tr key={asignatura}>
                            <td>{datos.nombre}</td>
                            {datos.notas.map((nota, i) => (
                                <td key={i}>{nota !== null ? nota.toFixed(1) : "-"}</td>
                            ))}
                            <td>{datos.promedio.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MisNotas;
