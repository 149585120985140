import React, { useState, useEffect } from 'react';
import directorService from '../../services/directorService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './directorCss/VerCursos.css';

function VerCursos() {
  const [cursos, setCursos] = useState([]);
  const [editingCurso, setEditingCurso] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    grado: ''
  });

  useEffect(() => {
    const fetchCursos = async () => {
      try {
        const cursosData = await directorService.obtenerCursos();
        setCursos(cursosData.cursos);
      } catch (error) {
        console.error("Error al obtener los cursos:", error);
        toast.error('Error al obtener los cursos. Inténtalo nuevamente.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    fetchCursos();
  }, []);

  const handleDelete = async (id) => {
    try {
      await directorService.eliminarCurso(id);
      setCursos(cursos.filter((curso) => curso.id !== id));
      toast.success('Curso eliminado con éxito.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error al eliminar el curso:", error);
      toast.error('Error al eliminar el curso. Inténtalo nuevamente.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleEdit = (curso) => {
    setEditingCurso(curso);
    setFormData({
      nombre: curso.nombre,
      grado: curso.grado
    });
  };

  const handleCancel = () => {
    setEditingCurso(null);
    setFormData({
      nombre: '',
      grado: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSave = async () => {
    try {
      await directorService.modificarCurso(editingCurso.id, formData);
      setCursos(cursos.map((curso) => curso.id === editingCurso.id ? { ...curso, ...formData } : curso));
      setEditingCurso(null);
      setFormData({
        nombre: '',
        grado: ''
      });
      toast.success('Curso modificado con éxito.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error al modificar el curso:", error);
      toast.error('Error al modificar el curso. Inténtalo nuevamente.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="Cursos-container">
      <h2>Cursos</h2>
      <table className="styled-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Grado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {cursos.map((curso) => (
            <tr key={curso.id}>
              <td>{curso.id}</td>
              <td>{curso.nombre}</td>
              <td>{curso.grado}</td>
              <td>
                <button className="edit-button" onClick={() => handleEdit(curso)}>Modificar</button>
                <button className="delete-button" onClick={() => handleDelete(curso.id)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingCurso && (
        <div className="edit-form">
          <h3>Modificar Curso</h3>
          <label>
            Nombre:
            <input
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
            />
          </label>
          <label>
            Grado:
            <input
              type="text"
              name="grado"
              value={formData.grado}
              onChange={handleChange}
            />
          </label>
          <div className="edit-form-buttons">
            <button className="save-button" onClick={handleSave}>Aceptar</button>
            <button className="cancel-button" onClick={handleCancel}>Cancelar</button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default VerCursos;
