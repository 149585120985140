import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdministradorService from '../../services/administradorService';
import './municipalidad/verDirectores.css'; // Importar el archivo CSS para estilos

function VerDirectores() {
    const [directores, setDirectores] = useState([]);
    const [editingDirector, setEditingDirector] = useState(null);
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        direccion: '',
        telefono: '',
        correo: '',
        fecha_nacimiento: ''
    });

    useEffect(() => {
        const fetchDirectores = async () => {
            try {
                const directoresData = await AdministradorService.obtenerDirectores();
                setDirectores(directoresData.directores);
            } catch (error) {
                console.error("Error al obtener los directores:", error);
                toast.error('Error al obtener los directores. Inténtalo nuevamente.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };
        fetchDirectores();
    }, []);

    const handleDelete = async (rut) => {
        try {
            await AdministradorService.eliminarDirector(rut);
            setDirectores(directores.filter((director) => director.rut !== rut));
            toast.success('Director eliminado con éxito.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.error("Error al eliminar el director:", error);
            toast.error('Error al eliminar el director. Inténtalo nuevamente.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleEdit = (director) => {
        setEditingDirector(director);
        setFormData({
            nombre: director.nombre,
            apellido: director.apellido,
            direccion: director.direccion,
            telefono: director.telefono,
            correo: director.correo,
            fecha_nacimiento: director.fecha_nacimiento
        });
    };

    const handleCancel = () => {
        setEditingDirector(null);
        setFormData({
            nombre: '',
            apellido: '',
            direccion: '',
            telefono: '',
            correo: '',
            fecha_nacimiento: ''
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = async () => {
        try {
            await AdministradorService.modificarDirector(editingDirector.rut, formData);
            setDirectores(directores.map(director => director.rut === editingDirector.rut ? { ...director, ...formData } : director));
            setEditingDirector(null);
            setFormData({
                nombre: '',
                apellido: '',
                direccion: '',
                telefono: '',
                correo: '',
                fecha_nacimiento: ''
            });
            toast.success('Director modificado con éxito.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.error("Error al modificar el director:", error);
            toast.error('Error al modificar el director. Inténtalo nuevamente.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className="Directores-container">
            <h2>Directores</h2>
            <table className="styled-table">
                <thead>
                    <tr>
                        <th>RUT</th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Dirección</th>
                        <th>Teléfono</th>
                        <th>Correo</th>
                        <th>Fecha de Nacimiento</th>
                        <th>Modificar</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {directores.map((director) => (
                        <tr key={director.rut}>
                            <td>{director.rut}</td>
                            <td>{director.nombre}</td>
                            <td>{director.apellido}</td>
                            <td>{director.direccion}</td>
                            <td>{director.telefono}</td>
                            <td>{director.correo}</td>
                            <td>{director.fecha_nacimiento}</td>
                            <td><button className="edit-button" onClick={() => handleEdit(director)}>Modificar</button></td>
                            <td><button className="delete-button" onClick={() => handleDelete(director.rut)}>Eliminar</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {editingDirector && (
                <div className="edit-form">
                    <h3>Modificar Director</h3>
                    <label>
                        Nombre:
                        <input
                            type="text"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Apellido:
                        <input
                            type="text"
                            name="apellido"
                            value={formData.apellido}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Dirección:
                        <input
                            type="text"
                            name="direccion"
                            value={formData.direccion}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Teléfono:
                        <input
                            type="text"
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Correo:
                        <input
                            type="email"
                            name="correo"
                            value={formData.correo}
                            onChange={handleChange}
                        />
                    </label>
                    <label>
                        Fecha de Nacimiento:
                        <input
                            type="date"
                            name="fecha_nacimiento"
                            value={formData.fecha_nacimiento}
                            onChange={handleChange}
                        />
                    </label>
                    <div className="edit-form-buttons">
                        <button className="save-button" onClick={handleSave}>Aceptar</button>
                        <button className="cancel-button" onClick={handleCancel}>Cancelar</button>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    );
}

export default VerDirectores;
