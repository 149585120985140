import React, { useState, useEffect } from 'react';
import directorService from '../../services/directorService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './directorCss/verEstudiantes.css'; // Importar el archivo CSS para estilos

function VerEstudiantes() {
  const [estudiantes, setEstudiantes] = useState([]);
  const [editingEstudiante, setEditingEstudiante] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    direccion: '',
    telefono: '',
    correo: '',
    fecha_nacimiento: ''
  });

  // Obtener los estudiantes
  useEffect(() => {
    const fetchEstudiantes = async () => {
      try {
        const estudiantesData = await directorService.obtenerEstudiantes();
        setEstudiantes(estudiantesData.estudiantes);
      } catch (error) {
        console.error("Error al obtener los estudiantes:", error);
        toast.error('Error al obtener los estudiantes. Inténtalo nuevamente.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    fetchEstudiantes();
  }, []);

  const handleDelete = async (rut) => {
    try {
      await directorService.eliminarEstudiante(rut);
      setEstudiantes(estudiantes.filter((estudiante) => estudiante.rut !== rut));
      toast.success('Estudiante eliminado con éxito.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error al eliminar el estudiante:", error);
      toast.error('Error al eliminar el estudiante. Inténtalo nuevamente.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleEdit = (estudiante) => {
    setEditingEstudiante(estudiante);
    setFormData({
      nombre: estudiante.nombre,
      apellido: estudiante.apellido,
      direccion: estudiante.direccion,
      telefono: estudiante.telefono,
      correo: estudiante.correo,
      fecha_nacimiento: estudiante.fecha_nacimiento
    });
  };

  const handleCancel = () => {
    setEditingEstudiante(null);
    setFormData({
      nombre: '',
      apellido: '',
      direccion: '',
      telefono: '',
      correo: '',
      fecha_nacimiento: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSave = async () => {
    try {
      await directorService.modificarEstudiante(editingEstudiante.rut, formData);
      setEstudiantes(estudiantes.map((estudiante) => estudiante.rut === editingEstudiante.rut ? { ...estudiante, ...formData } : estudiante));
      setEditingEstudiante(null);
      setFormData({
        nombre: '',
        apellido: '',
        direccion: '',
        telefono: '',
        correo: '',
        fecha_nacimiento: ''
      });
      toast.success('Estudiante modificado con éxito.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error al modificar el estudiante:", error);
      toast.error('Error al modificar el estudiante. Inténtalo nuevamente.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="Estudiantes-container">
      <h2>Estudiantes</h2>
      <table className="styled-table">
        <thead>
          <tr>
            <th>RUT</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Dirección</th>
            <th>Teléfono</th>
            <th>Correo</th>
            <th>Fecha de Nacimiento</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {estudiantes.map((estudiante) => (
            <tr key={estudiante.rut}>
              <td>{estudiante.rut}</td>
              <td>{estudiante.nombre}</td>
              <td>{estudiante.apellido}</td>
              <td>{estudiante.direccion}</td>
              <td>{estudiante.telefono}</td>
              <td>{estudiante.correo}</td>
              <td>{estudiante.fecha_nacimiento}</td>
              <td>
                <button className="edit-button" onClick={() => handleEdit(estudiante)}>Modificar</button>
                <button className="delete-button" onClick={() => handleDelete(estudiante.rut)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {editingEstudiante && (
        <div className="edit-form">
          <h3>Modificar Estudiante</h3>
          <label>
            Nombre:
            <input
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
            />
          </label>
          <label>
            Apellido:
            <input
              type="text"
              name="apellido"
              value={formData.apellido}
              onChange={handleChange}
            />
          </label>
          <label>
            Dirección:
            <input
              type="text"
              name="direccion"
              value={formData.direccion}
              onChange={handleChange}
            />
          </label>
          <label>
            Teléfono:
            <input
              type="text"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
            />
          </label>
          <label>
            Correo:
            <input
              type="email"
              name="correo"
              value={formData.correo}
              onChange={handleChange}
            />
          </label>
          <label>
            Fecha de Nacimiento:
            <input
              type="date"
              name="fecha_nacimiento"
              value={formData.fecha_nacimiento}
              onChange={handleChange}
            />
          </label>
          <div className="edit-form-buttons">
            <button className="save-button" onClick={handleSave}>Aceptar</button>
            <button className="cancel-button" onClick={handleCancel}>Cancelar</button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default VerEstudiantes;
