import React, { useState, useEffect } from 'react';
import profesorService from '../../services/profesorService';
import './profesorCss/verCurso.css';

const VerCurso = ({ idAsignatura }) => {
    const [curso, setCurso] = useState(null);
    const [alumnos, setAlumnos] = useState([]);
    const [asignaturaNombre, setAsignaturaNombre] = useState("");
    const [cursoNombre, setCursoNombre] = useState("");
    const [cursoGrado, setCursoGrado] = useState("");

    const [editIndex, setEditIndex] = useState(null);
    const [editRut, setEditRut] = useState(null);
    const [editNota, setEditNota] = useState("");

    useEffect(() => {
        const fetchCurso = async () => {
            try {
                const cursoData = await profesorService.obtenerCursoAsignatura(idAsignatura);
                console.log("Datos del curso:", cursoData);
                if (cursoData) {
                    setCurso(cursoData);
                    setAsignaturaNombre(cursoData.asignatura.nombre || 'Sin nombre');
                    setCursoNombre(cursoData.curso_nombre || 'Sin nombre');
                    setCursoGrado(cursoData.curso_grado || 'Sin grado');

                    const inscripcionesData = await profesorService.obtenerEstudiantes(cursoData.id);
                    const alumnosData = await Promise.all(inscripcionesData.map(async (inscripcion) => {
                        const calificaciones = await profesorService.obtenerCalificaciones(inscripcion.estudiante_rut, idAsignatura);
                        const notas = Array(8).fill(0);

                        calificaciones.forEach((calificacion) => {
                            if (calificacion.nota_numero <= 8) {
                                notas[calificacion.nota_numero - 1] = calificacion.nota;
                            }
                        });

                        return {
                            rut: inscripcion.estudiante_rut,
                            nombre: inscripcion.estudiante_nombre,
                            apellido: inscripcion.estudiante_apellido,
                            notas: notas,
                            promedio: (notas.reduce((acc, curr) => acc + parseFloat(curr), 0) / notas.length).toFixed(2)
                        };
                    }));
                    setAlumnos(alumnosData);
                }
            } catch (error) {
                console.error("Error al obtener el curso y alumnos:", error);
            }
        };
        fetchCurso();
    }, [idAsignatura]);

    const handleNotaChange = (index, alumnoRut, nuevaNota) => {
        setEditIndex(index);
        setEditRut(alumnoRut);
        setEditNota(nuevaNota);
    };

    const confirmarNota = async () => {
        const nuevaNota = parseFloat(editNota);
        if (nuevaNota >= 1.0 && nuevaNota <= 7.0) {
            const alumno = alumnos.find(al => al.rut === editRut);
            if (alumno) {
                alumno.notas[editIndex] = nuevaNota;
                try {
                    await profesorService.ingresarCalificacion(editRut, idAsignatura, editIndex + 1, nuevaNota);
                } catch (error) {
                    console.error("Error al ingresar la calificación:", error);
                }
                alumno.promedio = (alumno.notas.reduce((acc, curr) => acc + parseFloat(curr), 0) / alumno.notas.length).toFixed(2);
                setAlumnos([...alumnos]);
                setEditIndex(null);
                setEditRut(null);
                setEditNota("");
            }
        } else {
            console.error("La nota debe estar entre 1,0 y 7,0.");
        }
    };

    if (!curso) {
        return <div>Cargando...</div>;
    }

    return (
        <div className="curso-detalle-container2">
            <div className="header">
                <h2>{asignaturaNombre} {cursoNombre} - {cursoGrado}</h2>
                <h3>Libro de Clases</h3>
            </div>
            <div className="tabla-container2">
                <table className="tabla-alumnos2">
                    <thead>
                        <tr>
                            <th>RUT</th>
                            <th>Nombre Apellido</th>
                            {Array.from({ length: 8 }, (_, i) => (
                                <th key={i}>N{i + 1}</th>
                            ))}
                            <th>Promedio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {alumnos.map((alumno) => (
                            <tr key={alumno.rut}>
                                <td>{alumno.rut}</td>
                                <td>{alumno.nombre} {alumno.apellido}</td>
                                {alumno.notas.map((nota, index) => (
                                    <td key={index}>
                                        <input
                                            type="number"
                                            step="0.1"
                                            min="1.0"
                                            max="7.0"
                                            value={editRut === alumno.rut && editIndex === index ? editNota : nota}
                                            onMouseEnter={() => {
                                                setEditIndex(index);
                                                setEditRut(alumno.rut);
                                            }}
                                            onChange={(e) => handleNotaChange(index, alumno.rut, e.target.value)}
                                        />
                                        {editRut === alumno.rut && editIndex === index && (
                                            <button onClick={confirmarNota}>Confirmar</button>
                                        )}
                                    </td>
                                ))}
                                <td>{alumno.promedio}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VerCurso