import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdministradorService from '../../services/administradorService';
import './municipalidad/verColegios.css'; 

function VerColegios() {
    const [colegios, setColegios] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState({ id: '', nombre: '', direccion: '', telefono: '' });

    useEffect(() => {
        const fetchColegios = async () => {
            try {
                const colegiosData = await AdministradorService.obtenerColegios();
                setColegios(colegiosData);
            } catch (error) {
                console.error("Error al obtener los colegios:", error);
                toast.error('Error al obtener los colegios. Inténtalo nuevamente.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };
        fetchColegios();
    }, []);

    const handleDelete = async (id) => {
        try {
            await AdministradorService.eliminarColegio(id);
            setColegios(colegios.filter((colegio) => colegio.id !== id));
            toast.success('Colegio eliminado con éxito.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.error("Error al eliminar el colegio:", error);
            toast.error('Error al eliminar el colegio. Inténtalo nuevamente.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleEdit = (colegio) => {
        setEditData(colegio);
        setEditMode(true);
    };

    const handleSave = async () => {
        try {
            await AdministradorService.modificarColegio(editData.id, editData);
            setColegios(colegios.map((colegio) => (colegio.id === editData.id ? editData : colegio)));
            setEditMode(false);
            toast.success('Colegio modificado con éxito.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.error("Error al modificar el colegio:", error);
            toast.error('Error al modificar el colegio. Inténtalo nuevamente.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div className="Colegios-container">
            <h2>Colegios</h2>
            <table className="styled-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Dirección</th>
                        <th>Teléfono</th>
                        <th>Modificar</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {colegios.map((colegio) => (
                        <tr key={colegio.id}>
                            <td>{colegio.id}</td>
                            <td>{colegio.nombre}</td>
                            <td>{colegio.direccion}</td>
                            <td>{colegio.telefono}</td>
                            <td><button className="edit-button" onClick={() => handleEdit(colegio)}>Editar</button></td>
                            <td><button className="delete-button" onClick={() => handleDelete(colegio.id)}>Eliminar</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {editMode && (
                <div className="edit-form">
                    <h3>Modificar Colegio</h3>
                    <input
                        type="text"
                        placeholder="Nombre"
                        value={editData.nombre}
                        onChange={(e) => setEditData({ ...editData, nombre: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Dirección"
                        value={editData.direccion}
                        onChange={(e) => setEditData({ ...editData, direccion: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Teléfono"
                        value={editData.telefono}
                        onChange={(e) => setEditData({ ...editData, telefono: e.target.value })}
                    />
                    <button className="save-button" onClick={handleSave}>Guardar</button>
                    <button className="cancel-button" onClick={() => setEditMode(false)}>Cancelar</button>
                </div>
            )}

            <ToastContainer />
        </div>
    );
}

export default VerColegios;
